ve {
 animation: fadeIn 2s;
}

img {
  animation: createBox .5s;
}

.slick-arrow {
  color: #161517 !important;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes createBox {
  from {
    transform: scale(0)
  } to {
    transform: scale(1)
  }
}

*{
  scroll-margin-top: 96px;
}